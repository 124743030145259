
import ScrollingListNft from "../components/ScrollingListNft";
import { useDispatch, useSelector } from "react-redux";
import Input from "../UI/Input";
import { useEffect, useState } from "react";
import { transferNft } from "../store/reducer";

const ProfileNftCollection = () => {
    const { balance, selfNfts } = useSelector((state) => state.web3);
    const dispatch = useDispatch()
    const [currNft, setCurrNft] = useState(null)
    const [address, setAddress] = useState('')

    useEffect(() => {
        setCurrNft(null)
    }, [selfNfts])

    return(
        <>
            <div className="profile--collection">
                <div className="profile--collection__content">
                    <div className="profile--collection__top">
                        <div className="profile--collection__top--title">
                            Balance
                        </div>
                        <div className="profile--collection__top--amount">
                            {balance} LSYS
                        </div>
                    </div>
                    <ScrollingListNft nftListItem={selfNfts} activeId={currNft} onClick={(id) => setCurrNft(id)} />

                    <Input title="send" disabled={!currNft} label="Receiver address" type="text" onChange={(e) => setAddress(e.target.value)} value={address} onClick={() => dispatch(transferNft({ nftID: currNft, receiver: address }))} />
                </div>
            </div>
        </>
        
    )
}

export default ProfileNftCollection