
const Input = ({value, label, type, style, onChange, disabled, onClick, title="write"}) => {

    return (
        <>
            <div className="input--block__wrapper">
                <input className="input--block" type={type} placeholder={label} value={value} style={{...style}} onChange={onChange} />
                <button className="input--btn" onClick={onClick} disabled={disabled}>{title}</button>
            </div>
        </>
    )
}
export default Input